<div
  class="logate-chat-minimized"
  [style.background]="(configService.config$ | async)?.color"
  (click)="open()"
>
  <div class="logate-chat-icon"></div>

  <div
    class="logate-chat-minimized__counter"
    *ngIf="messageCounter as unreadMessages"
  >
    <ng-container *ngIf="unreadMessages !== 0">{{
      unreadMessages
    }}</ng-container>
  </div>
</div>
