import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfigService } from 'app/core/services/config.service';

@Component({
  selector: 'cc-chat-detail-term',
  templateUrl: './detail-term.component.html',
  styleUrls: ['./detail-term.component.scss'],
})
export class DetailTermComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  constructor(public configService: ConfigService) {}

  ngOnInit(): void {
    this.addTermControls();
  }

  addTermControls(): void {
    this.form.addControl(
      'termsOfUse',
      new UntypedFormControl(false, Validators.requiredTrue)
    );
    if (!this.configService.getConfig().alternateTerms)
      this.form.addControl(
        'acceptTerms',
        new UntypedFormControl(false, Validators.requiredTrue)
      );
  }
}
