<form
  class="logate-chat-interaction-form"
  [formGroup]="form"
  (ngSubmit)="(form.valid || file) && sendMessage()"
>
  <div class="logate-chat-interaction-form__field">
    <span
      class="logate-chat-interaction-form__field--icon"
      [ngClass]="{
        'logate-chat-interaction-form__field--icon-active':
          isEmojiPickerVisible,
        'logate-chat-interaction-form__field--icon-inactive':
          !isEmojiPickerVisible
      }"
      (click)="toggleIsEmojiPickerVisible()"
    ></span>
    <div
      *ngIf="!file"
      class="logate-chat-interaction-form__field--icon logate-chat-interaction-form__field--icon-attachment"
      (click)="showFileInput()"
    >
      <input
        type="file"
        #fileInput
        style="display: none"
        (change)="handleFileInputChange($event)"
      />
    </div>
    <div *ngIf="file" class="attachment-info">
      <div class="attachment-item">
        <div matTooltip="ddd">
          {{ messageService.formatFileName(file.name) }}
        </div>
        <div class="attachment-item-size">
          {{ messageService.formatFileSize(file.size) }}
        </div>
        <div
          class="attachment-item-remove-btn"
          (click)="removeItem(file)"
        ></div>
      </div>
    </div>
    <textarea
      autosize
      *ngIf="!file"
      type="text"
      class="logate-chat-interaction-form__field--input"
      (keydown.enter)="enterClicked($event)"
      formControlName="message"
      placeholder="{{ 'interaction.form.write-here' | translate }}"
    ></textarea>
  </div>

  <button
    type="submit"
    class="logate-chat-interaction-form__submit"
    [ngClass]="{
      'logate-chat-interaction-form__submit--active': message.valid || file,
      'logate-chat-interaction-form__submit--inactive': message.invalid && !file
    }"
  >
    <span class="logate-chat-interaction-form__submit--icon"> </span>
  </button>

  <emoji-mart
    (emojiSelect)="addEmoji($event)"
    *ngIf="isEmojiPickerVisible"
    [@openClose]
    emoji="point_up"
    class="logate-chat-interaction-form__emoji-mart"
    [isNative]="true"
    [showPreview]="false"
    title="{{ 'interaction.form.emoji' | translate }}"
  >
  </emoji-mart>
</form>
