import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '../enums/local-storage.enum';
import { EntryData } from '../models/entry-data';
import { ContactService } from './contact.service';
import { SessionService } from './session.service';
import { SocketConnectionService } from './socket-connection.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class EntryDataService {
  private entryData = new BehaviorSubject<EntryData>(null);
  public entryData$ = this.entryData.asObservable();

  constructor(
    private socketConnectionService: SocketConnectionService,
    private sessionService: SessionService,
    private contactService: ContactService,
    private stateService: StateService
  ) {
    this.entryData.next(
      JSON.parse(localStorage.getItem(LocalStorage.LOGATE_CHAT_ENTRY_DATA))
    );
  }

  setEntryData(entryData: EntryData) {
    this.entryData.next(entryData);
    localStorage.setItem(
      LocalStorage.LOGATE_CHAT_ENTRY_DATA,
      JSON.stringify(entryData)
    );
  }

  getEntryData() {
    return JSON.parse(
      localStorage.getItem(LocalStorage.LOGATE_CHAT_ENTRY_DATA)
    );
  }

  initializeChatWithEntryData(entryData: EntryData, contactInfo: string) {
    if (!this.sessionService.chatSessionId) {
      this.socketConnectionService.establishWebSocketConnection();
      this.sessionService.openSession(entryData);
      this.setEntryData(entryData);
      this.contactService.setContactInfo(contactInfo);
    }
    this.stateService.setToInteraction();
  }
}
