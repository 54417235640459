export enum State {
  DETAIL = 'DETAIL',
  INTERACTION = 'INTERACTION',
  CUSTOMER_EXPERIENCE = 'CUSTOMER_EXPERIENCE',
  MINIMIZED = 'MINIMIZED',
  FINISHED = 'FINISHED',
  DISABLED_INTERACTION = 'DISABLED_INTERACTION',
  MINIMIZED_HIDDEN = 'MINIMIZED_HIDDEN',
  CHOOSE_INTERACTION_TYPE = 'CHOOSE_INTERACTION_TYPE',
}
