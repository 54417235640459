import { Component, Input, OnInit } from '@angular/core';
import { ChannelTypes } from '../ChannelTypes';

@Component({
  selector: 'cc-chat-web-chat-icon',
  templateUrl: './web-chat-icon.component.html',
  styleUrls: ['./web-chat-icon.component.scss'],
})
export class WebChatIconComponent implements OnInit {
  @Input() color: string;
  @Input() hoveredChannel: string | null = null;

  ChannelTypes = ChannelTypes;

  constructor() {}

  ngOnInit(): void {}
}
