<div
  class="logate-chat-interaction-message"
  [ngClass]="'logate-chat-interaction-message__' + message.direction"
>
  <div
    *ngIf="
      message.direction === 'OUT' &&
      message.isQuickReply === false &&
      !message.file
    "
    class="logate-chat-interaction-message__icon"
    [style.background-image]="
      (configService.config$ | async)?.operatorLogoBackgroundImageUrl
        ? 'url(' +
          (configService.config$ | async)?.operatorLogoBackgroundImageUrl +
          ')'
        : ''
    "
    [style.height]="
      (configService.config$ | async)?.operatorLogoBackgroundImageUrl
        ? '40px'
        : ''
    "
    [style.background-size]="
      (configService.config$ | async)?.operatorLogoBackgroundImageUrl
        ? 'contain'
        : ''
    "
  ></div>

  <div>
    <div class="logate-chat-interaction-message-with-resend">
      <div
        *ngIf="
          deliveryStatus === InteractionEventType.MESSAGE_REJECTED ||
          deliveryStatus === InteractionEventType.MESSAGE_UNDELIVERABLE
        "
        class="logate-chat-resend-message"
        (click)="resendMessage()"
      ></div>
      <div *ngIf="!message.file">
        <div
          *ngIf="message.isQuickReply === false"
          class="logate-chat-unsuccessful logate-chat-interaction-message__content"
          [ngClass]="
            'logate-chat-interaction-message__content--' + message.direction
          "
          [ngStyle]="{
            background:
              message.bgColor ||
              (message.direction === 'IN'
                ? (configService.config$ | async)?.color
                : ''),
            color: message.textColor || ''
          }"
        >
          <img
            *ngIf="message.imageLink"
            class="quick-reply-icon"
            [src]="message.imageLink"
            alt="Quick Reply Icon"
          />
          <span [innerHTML]="formatMessageContent(message)"></span>
        </div>
        <div>
          <button
            [disabled]="message.disabled"
            (click)="onQuickReplyClick(message)"
            *ngIf="message.isQuickReply === true"
            class="logate-chat-unsuccessful logate-chat-interaction-message__content"
            [ngClass]="[
              'logate-chat-interaction-message__content--quick-reply--' +
                message.direction,
              'quick-reply-button'
            ]"
            [ngStyle]="{
              background: message.quickReplyData?.bgColor || '',
              color: message.quickReplyData?.textColor || '',
              'margin-left': '49px',
              padding: '0.5rem 0.75rem',
              cursor: message.disabled ? 'default' : 'pointer'
            }"
          >
            <img
              *ngIf="message.quickReplyData?.imageLink"
              class="quick-reply-icon"
              [src]="message.quickReplyData.imageLink"
            />
            <span [innerHTML]="formatMessageContent(message)"></span>
          </button>
        </div>
      </div>
      <div *ngIf="message.file && message.direction === 'IN'">
        <div
          class="logate-chat-unsuccessful logate-chat-interaction-message__content"
          [ngClass]="
            'logate-chat-interaction-message__content--' + message.direction
          "
          [ngStyle]="{
            background:
              message.direction === 'IN'
                ? (configService.config$ | async)?.color
                : ''
          }"
        >
          <div class="attachment-icon"></div>
          <div class="attachment-message">{{ message.fileData.name }}</div>
          <div class="attachment-message">
            {{ messageService.formatFileSize(message.fileData.size) }}
          </div>
        </div>
      </div>
      <div *ngIf="message.file && message.direction === 'OUT'">
        <div
          class="logate-chat-unsuccessful logate-chat-interaction-message__content"
          [ngClass]="
            'logate-chat-interaction-message__content--' + message.direction
          "
          [ngStyle]="{
            'margin-left': '49px',
            cursor: 'pointer'
          }"
          (click)="downloadFile()"
        >
          <div class="attachment-icon-out"></div>
          <div class="attachment-message-out">
            <div *ngIf="message.predefined == true">
              <span [innerHTML]="formatMessageContent(message)"></span>
            </div>
            <div class="attachment-message-out-file-name">
              {{ message.fileData.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="message.direction === 'IN'"
      class="logate-chat-message-date-info"
    >
      {{ message.getFormattedTime() }}
      <div
        *ngIf="deliveryStatus === InteractionEventType.MESSAGE_ACCEPTED"
        class="logate-chat-delivery-status logate-chat-delivery-status__sent"
      ></div>
      <div
        *ngIf="deliveryStatus === InteractionEventType.MESSAGE_DELIVERED"
        class="logate-chat-delivery-status logate-chat-delivery-status__delivered"
      ></div>
    </div>
  </div>
</div>
