import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cc-chat-customer-experience-submit',
  templateUrl: './customer-experience-submit.component.html',
  styleUrls: ['./customer-experience-submit.component.scss']
})
export class CustomerExperienceSubmitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
