import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerExperienceComponent } from './customer-experience.component';
import { SharedModule } from '../../core/shared/shared.module';
import { CustomerExperienceDescriptionComponent } from './customer-experience-description/customer-experience-description.component';
import { CustomerExperienceSatisfactionComponent } from './customer-experience-satisfaction/customer-experience-satisfaction.component';
import { CustomerExperienceRecommendComponent } from './customer-experience-recommend/customer-experience-recommend.component';
import { CustomerExperienceRatingComponent } from './customer-experince-rating/customer-experience-rating.component';
import { CustomerService } from './services/customer.service';
import { CustomerExperienceSubmitComponent } from './customer-experience-submit/customer-experience-submit.component';



@NgModule({
  declarations: [
    CustomerExperienceComponent,
    CustomerExperienceDescriptionComponent,
    CustomerExperienceSatisfactionComponent,
    CustomerExperienceRecommendComponent,
    CustomerExperienceRatingComponent,
    CustomerExperienceSubmitComponent
  ],
  providers: [
    CustomerService
  ],
  exports: [
    CustomerExperienceComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class CustomerExperienceModule { }
