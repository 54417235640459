import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InteractionState } from '../models/interaction-state';
import { LocalStorage } from 'app/core/enums/local-storage.enum';

@Injectable({
  providedIn: 'root',
})
export class InteractionStateService {
  private previousState: InteractionState = null;

  private state = new BehaviorSubject<InteractionState>(InteractionState.CHAT);
  public state$ = this.state.asObservable();

  private closedByAgent = new BehaviorSubject<Boolean>(false);
  public closedByAgent$ = this.closedByAgent.asObservable();

  private surveyLink = new BehaviorSubject<string>('');
  public surveyLink$ = this.surveyLink.asObservable();

  changeState(state: InteractionState): void {
    this.setPreviousState();
    this.state.next(state);
  }

  currentStateValue;

  getState(): InteractionState {
    return this.state.value;
  }

  setClosedByAgent(value: boolean) {
    localStorage.setItem(
      LocalStorage.LOGATE_CHAT_CLOSED_BY_AGENT,
      JSON.stringify(value)
    );
    this.closedByAgent.next(value);
  }

  setSurveyLink(surveyLink: string) {
    localStorage.setItem(LocalStorage.LOGATE_CHAT_SURVEY_LINK, surveyLink);
    this.surveyLink.next(surveyLink);
  }

  setToPreviousState(): void {
    return this.changeState(this.previousState ?? InteractionState.CHAT);
  }

  setToDisabledChat(): void {
    this.changeState(InteractionState.DISABLED_CHAT);
  }

  setToChat(): void {
    this.changeState(InteractionState.CHAT);
  }

  setToConfirmationFinish(): void {
    this.changeState(InteractionState.CONFIRMATION_FINISH);
  }

  setToFinished(): void {
    if (
      localStorage.getItem(LocalStorage.LOGATE_CHAT_CLOSED_BY_AGENT) === 'true'
    ) {
      this.closedByAgent.next(true);
    }
    if (localStorage.getItem(LocalStorage.LOGATE_CHAT_SURVEY_LINK)) {
      this.surveyLink.next(
        localStorage.getItem(LocalStorage.LOGATE_CHAT_SURVEY_LINK)
      );
    }
    this.changeState(InteractionState.FINISHED);
  }

  setPreviousState(): void {
    this.previousState = this.state.getValue();
  }

  getPreviousState(): InteractionState {
    return this.previousState;
  }

  resetState(): void {
    this.previousState = null;
  }

  destroyService() {
    this.state.next(InteractionState.CHAT);
    this.previousState = null;
  }
}
