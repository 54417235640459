import { Injectable } from '@angular/core';
import { InteractionState } from 'app/chat/interaction/models/interaction-state';
import { InteractionStateService } from 'app/chat/interaction/services/interaction-state.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocalStorage } from '../enums/local-storage.enum';
import { State } from '../enums/state.enum';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private previousState: State = null;

  public closeDialog = new BehaviorSubject<boolean>(false);
  public closeDialog$ = this.closeDialog.asObservable();
  private state = new BehaviorSubject<State>(State.MINIMIZED);

  constructor(
    private interactionStateService: InteractionStateService,
    private configService: ConfigService
  ) {
    // localStorage.setItem(LocalStorage.LOGATE_CHAT_STATE, this.state.getValue());
  }

  changeState(state: State): void {
    this.setPreviousState();

    this.state.next(state);

    localStorage.setItem(LocalStorage.LOGATE_CHAT_STATE, state);
  }

  getState(): Observable<State> {
    return this.state.asObservable();
  }

  getStateSubject() {
    return this.state;
  }

  setToPreviousState(): void {
    if (this.previousState === State.DISABLED_INTERACTION) {
      this.interactionStateService.changeState(InteractionState.FINISHED);
    }
    if (!!this.configService.getConfig().activeChannels)
      return this.changeState(State.CHOOSE_INTERACTION_TYPE);
    else return this.changeState(this.previousState ?? State.DETAIL);
  }

  setToMinimized(): void {
    this.changeState(State.MINIMIZED);
  }

  setToMinimizedHidden(): void {
    this.changeState(State.MINIMIZED_HIDDEN);
  }

  setToDetail(): void {
    this.changeState(State.DETAIL);
  }

  setToInteraction(): void {
    this.changeState(State.INTERACTION);
  }

  setToDisabledInteraction(): void {
    this.changeState(State.DISABLED_INTERACTION);
  }

  setToCustomerExperience(): void {
    this.changeState(State.CUSTOMER_EXPERIENCE);
  }

  setToFinished(): void {
    this.changeState(State.FINISHED);
  }

  setPreviousState(): void {
    this.previousState = this.state.getValue();
  }

  getPreviousState(): State {
    return this.previousState;
  }

  resetState(): void {
    this.setToMinimized();
    this.previousState = null;
  }

  destroyService() {
    this.closeDialog.next(false);
    this.previousState = null;
    this.state.next(State.MINIMIZED);
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_STATE);
  }
}
