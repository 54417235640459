import { Injectable } from '@angular/core';
import { SocketConnectionService } from './socket-connection.service';
import { SessionService } from './session.service';

@Injectable()
export class EventService {
  constructor(private socketConnectionService: SocketConnectionService, private sessionService: SessionService) {
  }

  sendIsTyping(): void {
    const request = {
      eventType: 'TYPING',
      chatSessionId: this.sessionService.getChatSessionId()
    };
    if (this.sessionService.getChatSessionId()) {
      this.socketConnectionService.publishEventTyping(request);
    }
  }
}
