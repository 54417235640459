import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from '../session.service';
import { Observable } from 'rxjs';
import { ApiPaths } from 'app/core/enums/api-paths.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpApiService {
  private apiUrl: string;
  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService
  ) {
    this.apiUrl = this.getBaseApi();
  }

  postFormData<T>(
    endpoint: string,
    formData: FormData,
    params?: HttpParams
  ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    const headers = this.createHeaders();
    return this.httpClient.post<T>(url, formData, { headers, params });
  }

  uploadFile<T>(file: File, externalId: string): Observable<T> {
    const formData = new FormData();
    formData.append('file', file);

    const params = new HttpParams()
      .set('chatSessionId', this.sessionService.getChatSessionId())
      .set('externalId', externalId);

    return this.postFormData<T>(ApiPaths.FILE_UPLOAD, formData, params);
  }

  downloadFileWithoutSessionId(downloadLink: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient.get(downloadLink, { headers, responseType: 'blob' });
  }

  downloadFile(downloadLink: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const params = new HttpParams().set(
      'chatSessionId',
      this.sessionService.getChatSessionId()
    );

    return this.httpClient.post(
      downloadLink,
      {},
      { headers, responseType: 'blob', params }
    );
  }

  createHeaders(): HttpHeaders {
    const headers = new HttpHeaders({});
    return headers;
  }

  getBaseApi() {
    const path = 'api';
    const logateChatElement = document.getElementById('logate-chat');
    let sourceLocation = window.location;
    let pathname = '/chat';
    if (logateChatElement) {
      sourceLocation = new URL((logateChatElement as any)?.src) as any;
      pathname = sourceLocation?.pathname.substring(
        0,
        sourceLocation?.pathname.lastIndexOf('/')
      );
    }
    const protocol =
      (sourceLocation as any)?.protocol === 'https:' ? 'https' : 'http';
    const host = (sourceLocation as any).host + pathname;
    const baseUrl = `${protocol}://${host}/${path}`;
    console.log('baseUrl in http-api service', baseUrl);
    return baseUrl;
  }
}
