export enum SocketPaths {

  SESSION_PUBLISH = '/app/open',
  SESSION_CLOSE = '/app/close',
  MESSAGE_PUBLISH = '/app/message',
  EVENT_TYPING_PUBLISH = '/app/event',

  SESSION_WATCH = '/user/exchange/amq.direct/session',

  MESSAGE_WATCH = '/user/exchange/amq.direct/reply',

  EVENT_WATCH = '/user/exchange/amq.direct/event'
}