import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';
import { EntryDataService } from 'app/core/services/entry-data.service';
import { MessageService } from 'app/core/services/message.service';
import { SessionService } from 'app/core/services/session.service';
import { StateService } from 'app/core/services/state.service';
import { UnreadMessageService } from 'app/core/services/unread-message.service';
import { InteractionStateService } from '../services/interaction-state.service';
import { TypingEventService } from '../services/typing-event.service';

@Component({
  selector: 'cc-chat-form-finish',
  templateUrl: './form-finish.component.html',
  styleUrls: ['./form-finish.component.scss'],
})
export class FormFinishComponent {
  @Input() title: String;
  @Input() subtitle: String;

  surveyLink: string;

  constructor(
    private stateService: StateService,
    private interactionStateService: InteractionStateService,
    private messageService: MessageService,
    private typingEventService: TypingEventService,
    private unreadMessageService: UnreadMessageService,
    private sessionService: SessionService,
    public configService: ConfigService,
    private entryData: EntryDataService,
    private cd: ChangeDetectorRef
  ) {}

  backToOldChat() {
    this.stateService.setToDisabledInteraction();
    this.messageService.setPreviousMessagesToDisabled();
    this.interactionStateService.setToDisabledChat();
  }

  startChatAgain() {
    this.stateService.destroyService();
    this.sessionService.destroyService();
    // check if ebankingdata have been sent
    const eBankingData = this.configService.getEBankingData();
    if (eBankingData) {
      // open connection and send data again
      this.entryData.initializeChatWithEntryData(
        eBankingData,
        eBankingData?.email != '' ? eBankingData?.email : eBankingData?.phone
      );
    } else {
      this.stateService.setToDetail();
    }
    this.interactionStateService.destroyService();
    this.messageService.destroyService();
    this.typingEventService.destroyService();
    // this.unreadMessageService.destroyService();
  }

  ngOnInit() {
    this.interactionStateService.surveyLink$.subscribe((link) => {
      console.log('link', link);
      this.surveyLink = link;
      this.cd.detectChanges();
    });
  }

  openSurveyLink() {
    window.open(this.surveyLink, '_blank');
  }
}
