<div class="logate-chat-header">
  <div class="logate-chat-header__operator">
    <div
      class="logate-chat-header__operator--icon"
      [style.background-image]="
        (configService.config$ | async)?.operatorLogoBackgroundImageUrl
          ? 'url(' +
            (configService.config$ | async)?.operatorLogoBackgroundImageUrl +
            ')'
          : ''
      "
      [style.height]="
        (configService.config$ | async)?.operatorLogoBackgroundImageUrl
          ? '40px'
          : ''
      "
      [style.background-size]="
        (configService.config$ | async)?.operatorLogoBackgroundImageUrl
          ? 'contain'
          : ''
      "
    ></div>
    <div>
      <div class="logate-chat-header__operator--title">
        {{ typingEventService.operatorName$ | async }}
      </div>
    </div>
  </div>

  <div class="logate-chat-header__actions">
    <div
      class="logate-chat-header__icon logate-chat-header__icon--minimise"
      (click)="minimize()"
    ></div>
    <div
      class="logate-chat-header__icon logate-chat-header__icon--close"
      (click)="close()"
    ></div>
  </div>
</div>
