import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageService } from './message.service';
import { StateService } from './state.service';
import { State } from '../enums/state.enum';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class UnreadMessageService {
  unreadMessages$ = new BehaviorSubject<number>(0);
  state: State;

  destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private stateService: StateService
  ) {
    this.stateService
      .getState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.state = state;
      });

    // Potrebno je da slusamo na dolazne poruke
    // this.messageService.watchForIncomingMessages().subscribe();
    this.watchForUnreadMessages();
  }

  watchForUnreadMessages(): void {
    this.messageService.messages$
      .pipe(takeUntil(this.destroy$))
      .subscribe((messages) => {
        if (this.messageService.resetUnreadMessages$.getValue()) {
          return;
        }
        if (this.state === State.MINIMIZED && messages.length) {
          this.unreadMessages$.next(this.unreadMessages$.getValue() + 1);
        } else {
          this.unreadMessages$.next(0);
        }
      });
  }

  destroyService() {
    this.unreadMessages$.next(0);
    this.state = undefined;
    this.destroy$.next();
    this.destroy$.complete();
  }
}
