<div class="logate-chat-cx-satisfaction customer-experience__box">
  <div class="logate-chat-customer-experience__title">
    <ng-content></ng-content>
  </div>
  <div class="logate-chat-cx-satisfaction__items">
    <div
      class="logate-chat-cx-satisfaction__item"
      *ngFor="let item of items; let i = index"
      (click)="onSelect(i)"
    >
      <div class="logate-chat-cx-satisfaction__rate">
        <div
          *ngIf="isSelected(i)"
          class="logate-chat-cx-satisfaction__rate--selected"
        ></div>
        <img
          [src]="item.image"
          [alt]="item.image"
          class="logate-chat-cx-satisfaction__icon"
        />
      </div>
      <div class="logate-chat-cx-satisfaction__text" *ngIf="item.title">
        {{ item.title }}
      </div>
    </div>
  </div>
</div>
