import { Injectable } from '@angular/core';
import { SocketConnectionService } from '../../../core/services/socket-connection.service';
import { filter, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SessionService } from '../../../core/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'app/core/enums/local-storage.enum';
import { InteractionEventType } from '../models/interaction-event-type';
import { MessageService } from 'app/core/services/message.service';

const TYPING_TIMEOUT = 2000;

@Injectable()
export class TypingEventService {
  DEFAULT_OPERATOR_NAME = this.translate.instant('default-operator-name');
  typing = new BehaviorSubject<boolean>(false);
  timeout: any;
  firstOpen = true;
  operatorName = new BehaviorSubject<string>(
    localStorage.getItem(LocalStorage.LOGATE_CHAT_OPERATOR_NAME) ??
      this.DEFAULT_OPERATOR_NAME
  );
  operatorName$ = this.operatorName.asObservable();

  constructor(
    private socketConnectionService: SocketConnectionService,
    private sessionService: SessionService,
    private translate: TranslateService,
    private messageService: MessageService
  ) {
    if (!localStorage.getItem(LocalStorage.LOGATE_CHAT_OPERATOR_NAME)) {
      this.setOperatorName('Operater podrške');
    }
  }

  getOperatorName() {
    return this.operatorName.value;
  }

  setOperatorName(operatorName: string) {
    this.operatorName.next(operatorName);
    localStorage.setItem(LocalStorage.LOGATE_CHAT_OPERATOR_NAME, operatorName);
  }

  initWatcher(): Observable<any> {
    return this.watchTyping().pipe(
      tap((m) => {
        console.log(m);
        if (this.firstOpen) {
          this.firstOpen = false;
          return;
        }
        if (m.eventType === 'TYPING') {
          this.typing.next(true);
          this.initTimeout();
        } else if (m.eventType === 'CHAT_OPENED') {
          this.operatorName.next(m.operatorName);
          localStorage.setItem(
            LocalStorage.LOGATE_CHAT_OPERATOR_NAME,
            m.operatorName
          );
        } else if (this.deliveryStatusEvent(m.eventType)) {
          this.messageService.setDeliveryStatus(m.externalId, m.eventType);
        }
      })
    );
  }

  deliveryStatusEvent(event: InteractionEventType) {
    if (
      event === InteractionEventType.MESSAGE_DELIVERED ||
      event === InteractionEventType.MESSAGE_ACCEPTED ||
      event === InteractionEventType.MESSAGE_REJECTED ||
      event === InteractionEventType.MESSAGE_UNDELIVERABLE
    ) {
      return true;
    } else {
      return false;
    }
  }

  watchTyping(): Observable<any> {
    return this.socketConnectionService
      .watchEvent()
      .pipe(map((message) => JSON.parse(message.body)));
  }

  publishTyping(): void {
    const request = {
      eventType: 'TYPING',
      chatSessionId: this.sessionService.chatSessionId,
    };
    if (this.sessionService.getChatSessionId()) {
      this.socketConnectionService.publishEventTyping(request);
    }
  }

  getTyping(): Observable<boolean> {
    return this.typing.asObservable();
  }

  initTimeout(): void {
    if (this.timeout) {
      this.removeTyping();
    }

    this.timeout = setTimeout(() => {
      this.typing.next(false);
    }, TYPING_TIMEOUT);
  }

  removeTyping(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  destroyService() {
    this.typing.next(false);
    this.timeout = undefined;
    this.firstOpen = true;
    this.operatorName.next('Operater podrške');
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_OPERATOR_NAME);
  }
}
