<div
  class="logate-chat-confirmation-wrapper"
  [style.background-color]="
    (configService.config$ | async)?.interactionMessagesBackground
  "
>
  <div class="logate-chat-close-dialog">
    <div class="logate-chat-title">
      {{
        (this.configService.getConfig().alternateTexts?.endQuestion
          ? "alternate-texts." +
            this.configService.getConfig().alternateTexts?.endQuestion
          : "interaction.confirmation.question"
        ) | translate
      }}
    </div>
    <div class="logate-chat-actions">
      <button class="logate-chat-back" (click)="back()">
        {{ "interaction.confirmation.back" | translate }}
      </button>
      <button class="logate-chat-close" (click)="endChat()">
        {{ "interaction.confirmation.end" | translate }}
      </button>
    </div>
  </div>
</div>
