<div class="logate-chat-interaction-type">
  <div class="logate-chat-interaction-type__header">
    <div
      [style.background-image]="
        (configService.config$ | async)?.logoBackgroundImageUrl
          ? 'url(' +
            (configService.config$ | async)?.logoBackgroundImageUrl +
            ')'
          : ''
      "
      class="logate-chat-interaction-type__header__logo"
    ></div>
    <span
      class="logate-chat-interaction-type__header--close"
      (click)="close()"
    ></span>
  </div>
  <div class="logate-chat-interaction-type__content">
    <div class="logate-chat-interaction-type__content--subtitle">
      Odaberite jednu od opcija kako biste nas kontaktirali
    </div>
    <div class="btns-container">
      <a *ngIf="!!activeChannels.viber" [href]="safeUrl">
        <div
          (mouseover)="onMouseOver(ChannelTypes.VIBER)"
          (mouseout)="onMouseOut()"
          [style.color]="hoveredChannel == ChannelTypes.VIBER ? color : 'white'"
          class="btn-wrapper"
        >
          <p>{{ "channel-types.viber" | translate }}</p>
          <cc-chat-viber-icon
            [color]="color"
            [hoveredChannel]="hoveredChannel"
          ></cc-chat-viber-icon>
        </div>
      </a>
      <a *ngIf="!!activeChannels.phone" href="tel:{{ activeChannels.phone }}">
        <div
          (mouseover)="onMouseOver(ChannelTypes.PHONE)"
          (mouseout)="onMouseOut()"
          [style.color]="hoveredChannel == ChannelTypes.PHONE ? color : 'white'"
          class="btn-wrapper"
        >
          <p>{{ "channel-types.phone" | translate }}</p>
          <cc-chat-phone-icon
            [color]="color"
            [hoveredChannel]="hoveredChannel"
          ></cc-chat-phone-icon>
        </div>
      </a>
      <div
        (mouseover)="onMouseOver(ChannelTypes.WEB_CHAT)"
        (mouseout)="onMouseOut()"
        [style.color]="
          hoveredChannel == ChannelTypes.WEB_CHAT ? color : 'white'
        "
        (click)="choseWebChat()"
        class="btn-wrapper"
      >
        <p>{{ "channel-types.web-chat" | translate }}</p>
        <cc-chat-web-chat-icon
          [color]="color"
          [hoveredChannel]="hoveredChannel"
        ></cc-chat-web-chat-icon>
      </div>
      <a
        *ngIf="!!activeChannels.email"
        href="mailto: {{ activeChannels.email }}"
      >
        <div
          (mouseover)="onMouseOver(ChannelTypes.EMAIL)"
          (mouseout)="onMouseOut()"
          [style.color]="hoveredChannel == ChannelTypes.EMAIL ? color : 'white'"
          class="btn-wrapper"
        >
          <p>{{ "channel-types.email" | translate }}</p>
          <cc-chat-email-icon
            [color]="color"
            [hoveredChannel]="hoveredChannel"
          ></cc-chat-email-icon>
        </div>
      </a>
    </div>
  </div>
  <div class="logate-chat-interaction-type__logate-logo"></div>
</div>
