import { Component, Input, OnInit } from '@angular/core';
import { ChannelTypes } from '../ChannelTypes';

@Component({
  selector: 'cc-chat-email-icon',
  templateUrl: './email-icon.component.html',
  styleUrls: ['./email-icon.component.scss'],
})
export class EmailIconComponent implements OnInit {
  @Input() color: string;
  @Input() hoveredChannel: string | null = null;

  ChannelTypes = ChannelTypes;
  constructor() {}

  ngOnInit(): void {}
}
