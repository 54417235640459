import { animate, style, transition, trigger } from '@angular/animations';

export const openCloseAnimation = [
  trigger(
    'openClose',
    [
      transition(
        ':enter',
        [
          style({
            transform: 'translateY(2rem)',
            opacity: 0
          }),
          animate('0.1s ease-out',
            style({
              opacity: 1,
              transform: 'translateY(0)',
            }))
        ]
      ),
      transition(
        ':leave',
        [
          style({
            opacity: 1,
            transform: 'translateY(0)',
          }),
          animate('0.1s ease-in',
            style({
                // height: 0,
                opacity: 0,
                transform: 'translateY(2rem)',
              }
            ))
        ]
      )
    ]
  )
];
