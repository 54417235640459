import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from 'app/core/enums/local-storage.enum';
import { ConfigService } from 'app/core/services/config.service';
import { SocketConnectionService } from 'app/core/services/socket-connection.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { openCloseAnimation } from '../core/animations/openCloseAnimation';
import { State } from '../core/enums/state.enum';
import { MessageService } from '../core/services/message.service';
import { SessionService } from '../core/services/session.service';
import { StateService } from '../core/services/state.service';
import { InteractionState } from './interaction/models/interaction-state';
import { InteractionStateService } from './interaction/services/interaction-state.service';
import { TypingEventService } from './interaction/services/typing-event.service';

@Component({
  selector: 'cc-chat-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [...openCloseAnimation],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent {
  @Input() config: string;
  @Input() language: any;
  @Input() ebankingdata: any;
  @Input() hidechat: any;
  @Input() showonclose: any;

  hiddenConfig;

  state = State;
  destroy$ = new Subject<void>();
  tabWasClosed = false;

  constructor(
    public stateService: StateService,
    private messageService: MessageService,
    private interactionStateService: InteractionStateService,
    private sessionService: SessionService,
    public configService: ConfigService,
    public translate: TranslateService,
    private socketConnectionService: SocketConnectionService,
    private cd: ChangeDetectorRef
  ) {
    if (localStorage.getItem(LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID)) {
      // page has been reloaded
      const stateStorage = localStorage.getItem(
        LocalStorage.LOGATE_CHAT_STATE
      ) as State;
      if (stateStorage !== State.INTERACTION) {
        this.socketConnectionService.establishWebSocketConnection();
      }
      this.sessionService.getNewSession();

      this.stateService.changeState(stateStorage);
    }
    if (this.language == null) {
      this.language = 'sr';
    }
  }

  ngOnInit() {
    this.configService.hidden$.subscribe((hidden) => {
      this.hiddenConfig = hidden;
      this.cd.detectChanges();
      if (this.hiddenConfig === false) {
        if (
          this.stateService.getStateSubject().value === State.MINIMIZED_HIDDEN
        ) {
          this.stateService.changeState(this.stateService.getPreviousState());
        } else {
          this.stateService.changeState(State.DETAIL);
        }
      }
    });
    if (this.language === 'me') {
      this.language = 'sr';
    }
    this.translate.use(this.language);
    this.stateService.getState().subscribe((state) => {
      this.cd.detectChanges();
      if (state === State.INTERACTION) {
        this.inititalizeChat();
      }

      if (state === State.MINIMIZED) {
        this.inititalizeChat(
          localStorage.getItem(LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID)
            ? false
            : true
        );
      }

      if (state === State.MINIMIZED_HIDDEN) {
        this.inititalizeChat(
          localStorage.getItem(LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID)
            ? false
            : true
        );
      }
    });

    this.interactionStateService.state$.subscribe((state) => {
      if (state === InteractionState.FINISHED) {
        this.destroy$.next();
        this.destroy$.complete();
      }
    });
    this.sessionService.sessionClosed$.subscribe((closed) => {
      if (closed) {
        this.interactionStateService.setToFinished();
      }
    });

    this.sessionService.sessionOpened$.subscribe((opened) => {
      if (opened) {
        this.messageService.sendGetStartedMessage();
      }
    });
  }

  inititalizeChat(resetUnreadMessages = true) {
    this.messageService
      .watchForIncomingMessages(resetUnreadMessages)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnChanges() {
    this.cd.detectChanges();
    if (this.config) {
      this.configService.setConfig(JSON.parse(this.config));
    }

    if (this.ebankingdata) {
      const entryData = JSON.parse(this.ebankingdata);
      this.configService.setEBankingData(entryData);
    }

    if (this.hidechat) {
      this.configService.setHidden(JSON.parse(this.hidechat));
    }

    if (this.showonclose) {
      this.configService.setShowonclose(JSON.parse(this.showonclose));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
