import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailComponent } from './detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DetailFormComponent } from './detail-form/detail-form.component';
import { DetailTermComponent } from './detail-term/detail-term.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'app/core/shared/shared.module';

@NgModule({
  declarations: [DetailComponent, DetailFormComponent, DetailTermComponent],
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  exports: [DetailComponent],
})
export class DetailModule {}
