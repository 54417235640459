import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cc-chat-customer-experience-recommend',
  templateUrl: './customer-experience-recommend.component.html',
  styleUrls: ['./customer-experience-recommend.component.scss']
})
export class CustomerExperienceRecommendComponent implements OnInit {
  @Output() rateClicked = new EventEmitter<number>();

  selectedItem: number;
  constructor() { }

  ngOnInit(): void {
  }

  itemSelected(item: number): void {
    this.selectedItem = item;
    this.rateClicked.emit(item);
  }

}
