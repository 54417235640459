import { Component, Input, OnInit } from '@angular/core';
import { ChannelTypes } from '../ChannelTypes';

@Component({
  selector: 'cc-chat-viber-icon',
  templateUrl: './viber-icon.component.html',
  styleUrls: ['./viber-icon.component.scss'],
})
export class ViberIconComponent implements OnInit {
  @Input() color: string;
  @Input() hoveredChannel: string | null = null;

  ChannelTypes = ChannelTypes;

  constructor() {}

  ngOnInit(): void {}
}
