import { Injectable } from '@angular/core';
import { IMessage } from '@stomp/stompjs';
import { InteractionStateService } from 'app/chat/interaction/services/interaction-state.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LocalStorage } from '../enums/local-storage.enum';
import { EntryData } from '../models/entry-data';
import { SocketConnectionService } from './socket-connection.service';
import { StateService } from './state.service';
import { RxStompService } from '@stomp/ng2-stompjs';

@Injectable()
export class SessionService {
  chatSessionId: string;
  private sessionClosed = new BehaviorSubject<boolean>(false);
  public sessionClosed$ = this.sessionClosed.asObservable();

  private sessionOpened = new BehaviorSubject<boolean>(false);
  public sessionOpened$ = this.sessionOpened.asObservable();

  destroy$ = new Subject<void>();

  constructor(
    private socketConnectionService: SocketConnectionService,
    private stateService: StateService,
    private rxStompService: RxStompService,
    private interactionStateService: InteractionStateService
  ) {
    this.chatSessionId = localStorage.getItem(
      LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID
    );
    this.rxStompService.connectionState$.subscribe((connState) => {
      if (connState === 1 && this.chatSessionId) {
        this.publishReconnect();
      }
    });
  }

  getNewSession(): void {
    this.destroy$ = new Subject<void>();
    this.socketConnectionService
      .watchSession()
      .pipe(takeUntil(this.destroy$))
      .subscribe((message: IMessage) => {
        const body = JSON.parse(message.body);
        this.chatSessionId = body.chatSessionId;
        localStorage.setItem(
          LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID,
          this.chatSessionId
        );
        if (body.status === 'CLOSED') {
          this.sessionClosed.next(true);
          this.interactionStateService.setSurveyLink('');
          this.interactionStateService.setClosedByAgent(false);
        } else if (body.status === 'OPENED') {
          this.sessionClosed.next(false);
          this.sessionOpened.next(true);
        } else if (body.status === 'NOT_FOUND') {
          this.sessionClosed.next(true);
          this.stateService.setToMinimized();
        } else if (body.status === 'CLOSED_BY_OPERATOR') {
          this.sessionClosed.next(true);
          this.interactionStateService.setClosedByAgent(true);
          this.interactionStateService.setSurveyLink('');
        } else if (body.status === 'SURVEY') {
          this.interactionStateService.setSurveyLink(body.data);
        }
      });
  }

  publishReconnect(): void {
    const request = {
      eventType: 'RECONNECT',
      chatSessionId: this.chatSessionId,
    };
    if (this.getChatSessionId()) {
      this.socketConnectionService.publishEventTyping(request);
    }
  }

  openSession(entryData: EntryData): void {
    this.getNewSession();
    this.socketConnectionService.publishSession({ ...entryData });
  }

  getChatSessionId(): string {
    return localStorage.getItem(LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID);
  }

  closeSession(): void {
    this.socketConnectionService.closeSession({
      chatSessionId: this.chatSessionId,
    });
    this.chatSessionId = '';

    this.interactionStateService.setSurveyLink('');
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID);
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_CLOSED_BY_AGENT);
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID);
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_SURVEY_LINK);
  }

  destroyService() {
    this.sessionOpened.next(false);
    this.sessionClosed.next(false);
    this.destroy$.next();
    this.destroy$.complete();
    this.chatSessionId = undefined;
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_CHAT_SESSION_ID);
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_CLOSED_BY_AGENT);
    localStorage.removeItem(LocalStorage.LOGATE_CHAT_SURVEY_LINK);
  }
}
