<svg
  xmlns="http://www.w3.org/2000/svg"
  width="48"
  height="48"
  viewBox="0 0 48 48"
>
  <defs>
    <linearGradient
      id="audio-a"
      x1="17.123%"
      x2="100%"
      y1="88.06%"
      y2="18.254%"
    >
      <stop offset="0%" stop-color="#FF6C40" />
      <stop offset="100%" stop-color="#FC815D" />
    </linearGradient>
    <linearGradient
      id="audio-b"
      x1="41.781%"
      x2="62.5%"
      y1="88.06%"
      y2="18.254%"
    >
      <stop offset="0%" stop-color="#FF6C40" />
      <stop offset="100%" stop-color="#FC815D" />
    </linearGradient>
    <linearGradient
      id="audio-c"
      x1="17.123%"
      x2="100%"
      y1="57.864%"
      y2="43.441%"
    >
      <stop offset="0%" stop-color="#FF6C40" />
      <stop offset="100%" stop-color="#FC815D" />
    </linearGradient>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <circle
      cx="14.009"
      cy="22.452"
      r="1.453"
      [attr.fill]="hoveredChannel == ChannelTypes.PHONE ? color : 'white'"
    />
    <circle
      cx="22.452"
      cy="21.694"
      r="1.453"
      [attr.fill]="hoveredChannel == ChannelTypes.PHONE ? color : 'white'"
    />
    <circle
      cx="22.452"
      cy="13.949"
      r="1.453"
      [attr.fill]="hoveredChannel == ChannelTypes.PHONE ? color : 'white'"
    />
    <path
      [attr.stroke]="hoveredChannel == ChannelTypes.PHONE ? color : 'white'"
      d="M31.5217684,27.8570105 C32.3849263,26.9189053 33.2034526,25.9370105 33.9251368,24.8869053 C37.0266105,20.3740632 37.3289263,16.9450105 37.3289263,15.7980632 C37.3289263,13.3382737 34.1600842,9.83006316 33.3516632,9.81995789 L31.5520842,9.81995789 C31.3087158,9.81995789 31.0872421,9.96648421 30.9937684,10.1913263 L28.3377684,16.4540632 C28.2299789,16.7092211 28.3108211,17.0064842 28.5322947,17.1723789 C31.0350316,19.0544842 31.8619789,19.6313263 31.8771368,20.5786947 C31.8771368,21.2431158 30.9836632,22.5845895 29.7104,24.0902737 L27.4729263,26.5146947 C25.1057684,28.8919579 22.4682947,31.0233263 21.4485053,31.0233263 C20.4868211,31.0132211 19.8518737,30.1946947 17.9697684,27.6911158 C17.8038737,27.4679579 17.5049263,27.3845895 17.2497684,27.4932211 L11.0442947,30.1399579 C10.8186105,30.2342737 10.6712421,30.4549053 10.6712421,30.6991158 L10.6712421,32.5020632 C10.6813474,33.3096421 14.1912421,36.4759579 16.6518737,36.4759579 C18.1306105,36.4759579 23.4224,35.9732211 29.9394526,29.5774316 L31.5217684,27.8570105 Z"
    />
    <line
      x1="33.355"
      x2="29.247"
      y1="9.056"
      y2="18.779"
      [attr.stroke]="hoveredChannel == ChannelTypes.PHONE ? color : 'white'"
    />
    <line
      x1="10"
      x2="19.656"
      y1="33.022"
      y2="28.786"
      [attr.stroke]="hoveredChannel == ChannelTypes.PHONE ? color : 'white'"
    />
  </g>
</svg>
