<div class="logate-chat-interaction">
  <cc-chat-operator-header></cc-chat-operator-header>
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="InteractionState.CHAT">
      <cc-chat-interaction-messages></cc-chat-interaction-messages>
      <cc-chat-interaction-form></cc-chat-interaction-form>
    </ng-container>
    <ng-container *ngSwitchCase="InteractionState.DISABLED_CHAT">
      <cc-chat-interaction-messages></cc-chat-interaction-messages>
      <!-- <cc-chat-interaction-form></cc-chat-interaction-form> -->
    </ng-container>
    <ng-container *ngSwitchCase="InteractionState.CONFIRMATION_FINISH">
      <cc-chat-confirmation-dialog></cc-chat-confirmation-dialog>
    </ng-container>
    <ng-container *ngSwitchCase="InteractionState.FINISHED">
      <cc-chat-form-finish
        [title]="
          (interactionStateService.closedByAgent$ | async)
            ? this.configService.getConfig().alternateTexts
                ?.endedByOperatorTitle
              ? 'alternate-texts.' +
                this.configService.getConfig().alternateTexts
                  ?.endedByOperatorTitle
              : 'interaction.ended-by-operator.title'
            : this.configService.getConfig().alternateTexts?.finishedTitle
            ? 'alternate-texts.' +
              this.configService.getConfig().alternateTexts?.finishedTitle
            : 'interaction.ended.title'
        "
        [subtitle]="
          (interactionStateService.closedByAgent$ | async)
            ? this.configService.getConfig().alternateTexts
                ?.endedByOperatorSubtitle
              ? 'alternate-texts.' +
                this.configService.getConfig().alternateTexts
                  ?.endedByOperatorSubtitle
              : 'interaction.ended-by-operator.subtitle'
            : this.configService.getConfig().alternateTexts?.finishedSubtitle
            ? 'alternate-texts.' +
              this.configService.getConfig().alternateTexts?.finishedSubtitle
            : 'interaction.ended.subtitle'
        "
      ></cc-chat-form-finish>
    </ng-container>
  </ng-container>

  <div
    *ngIf="typingEventService.getTyping() | async"
    class="logate-chat-typing"
  >
    <ng-container
      >{{ typingEventService.operatorName$ | async }} is typing</ng-container
    >
  </div>
  <div class="logate-chat-logate-logo"></div>
</div>
