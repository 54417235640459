import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';

@Injectable()
export class VisibilityService {
  constructor(private configService: ConfigService) {
    window.LogateChat = window.LogateChat || {};
    window.LogateChat.showChat = this.showChat.bind(this);
    window.LogateChat.hideChat = this.hideChat.bind(this);
    window.LogateChat.showOnClose = this.showOnClose.bind(this);
  }

  showChat() {
    console.log('Show chat');
    this.configService.setHidden(false);
  }

  hideChat() {
    console.log('Hide chat');
    this.configService.setHidden(true);
  }

  showOnClose(value: boolean) {
    console.log('setShowonclose', value);
    this.configService.setShowonclose(value);
  }
}
