import { Injectable } from '@angular/core';
import { StompConfigService } from './stomp-config.service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Observable } from 'rxjs';
import { SocketPaths } from '../enums/socket-paths.enum';
import { IMessage } from '@stomp/stompjs';

@Injectable()
export class SocketConnectionService {
  stompReady = new Observable();
  stompError = new Observable();

  constructor(
    private rxStompService: RxStompService,
    private stompConfigService: StompConfigService
  ) {
    // ----- STOMP DIAGNOSTICS -----
    [
      'connected$',
      'connectionState$',
      'serverHeaders$',
      'unhandledFrame$',
      'unhandledMessage$',
      'unhandledReceipts$',
      'stompErrors$',
      'webSocketErrors$',
    ].forEach((status) => {
      this.logFn(status, this.rxStompService[status]);
    });

    this.stompReady = this.rxStompService.connected$;
    this.stompError = this.rxStompService.stompErrors$;
  }

  establishWebSocketConnection(): void {
    if (
      this.rxStompService.connectionState$.value === 0 ||
      this.rxStompService.connectionState$.value === 1
    ) {
      return;
    }
    this.rxStompService.configure(this.stompConfigService.config());
    this.rxStompService.activate();
  }

  disconnectWebSocket(): void {
    if (
      this.rxStompService.connectionState$.value === 0 ||
      this.rxStompService.connectionState$.value === 1
    ) {
      this.rxStompService.deactivate();
    }
  }

  publishSession(body): void {
    this.publish(SocketPaths.SESSION_PUBLISH, body);
  }

  closeSession(body): void {
    this.publish(SocketPaths.SESSION_CLOSE, body);
  }

  publishMessage(body, headers): void {
    this.publish(SocketPaths.MESSAGE_PUBLISH, body, headers);
  }

  publishEventTyping(body): void {
    this.publish(SocketPaths.EVENT_TYPING_PUBLISH, body);
  }

  watchSession(): Observable<IMessage> {
    return this.rxStompService.watch(SocketPaths.SESSION_WATCH);
  }

  watchMessages(): Observable<IMessage> {
    return this.rxStompService.watch(SocketPaths.MESSAGE_WATCH);
  }

  watchEvent(): Observable<IMessage> {
    return this.rxStompService.watch(SocketPaths.EVENT_WATCH);
  }

  /**
   * Publish (i.e. send) message to destination (topic).
   *
   * @param dest Destination i.e. topic.
   * @param body Message i.e. payload.
   */
  private publish(dest, body, headers?): void {
    console.log(`Publish to '${dest}':`, body);
    this.rxStompService.publish({
      destination: dest,
      body: JSON.stringify(body),
      headers,
    });
  }

  /** Logging rxStomp statuses */
  private logFn(logger: string, obs: Observable<any>): void {
    obs.subscribe((value) => {
      console.log(logger, ': ', value);
      if (value.body) {
        console.log('body: ', value.body);
      }
    });
  }
}
