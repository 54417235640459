import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractionComponent } from './interaction.component';
import { InteractionMessagesComponent } from './interaction-messages/interaction-messages.component';
import { InteractionFormComponent } from './interaction-form/interaction-form.component';
import { InteractionMessageComponent } from './interaction-messages/interaction-message/interaction-message.component';
import { SharedModule } from '../../core/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InteractionUnreadLineComponent } from './interaction-messages/interaction-unread-line/interaction-unread-line.component';
import { TypingEventService } from './services/typing-event.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FormFinishComponent } from './form-finish/form-finish.component';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';

@NgModule({
  declarations: [
    InteractionComponent,
    InteractionMessagesComponent,
    InteractionFormComponent,
    InteractionMessageComponent,
    InteractionUnreadLineComponent,
    ConfirmationDialogComponent,
    FormFinishComponent,
  ],
  exports: [InteractionComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    PickerModule,
    BrowserAnimationsModule,
    TextareaAutosizeModule,
  ],
  providers: [TypingEventService],
})
export class InteractionModule {}
