import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorHeaderComponent } from '../components/operator-header/operator-header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OperatorHeaderComponent],
  imports: [CommonModule, TranslateModule],
  exports: [OperatorHeaderComponent, TranslateModule],
})
export class SharedModule {}
