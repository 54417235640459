import { Component, OnInit } from '@angular/core';
import { CustomerExperienceItem } from './models/customer-experience-item.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'cc-chat-customer-experience',
  templateUrl: './customer-experience.component.html',
  styleUrls: ['./customer-experience.component.scss']
})
export class CustomerExperienceComponent implements OnInit {
  supportItems: CustomerExperienceItem[];
  betterItems: CustomerExperienceItem[];

  formSubmitted: boolean = false;
  prefix: string;

  constructor() {
  }

  ngOnInit(): void {
    this.prefix = 'assets/icons/cx';
    this.prepareSupportItems();
    this.prepareBetterItems();
  }

  prepareSupportItems(): void {
    this.supportItems = [
      { image: `${this.prefix}red_smile.svg` },
      { image: `${this.prefix}yellow_smile.svg` },
      { image: `${this.prefix}green_smile.svg` }
    ];
  }

  prepareBetterItems(): void {
    this.betterItems = [
      { image: `${this.prefix}red_smile.svg`, title: 'Oblast 1' },
      { image: `${this.prefix}yellow_smile.svg`, title: 'Oblast 2' },
      { image: `${this.prefix}green_smile.svg`, title: 'Oblast 3' },
      { image: `${this.prefix}red_smile.svg`, title: 'Oblast 4' },
      { image: `${this.prefix}yellow_smile.svg`, title: 'Oblast 5' },
      { image: `${this.prefix}green_smile.svg`, title: 'Oblast 6' },
    ];
  }

  onSubmit() {
    this.formSubmitted = true;
  }

}
