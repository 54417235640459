import { Component } from '@angular/core';
import { ConfigService } from 'app/core/services/config.service';
import { SessionService } from 'app/core/services/session.service';
import { InteractionStateService } from '../services/interaction-state.service';

@Component({
  selector: 'cc-chat-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  constructor(
    private interactionStateService: InteractionStateService,
    private sessionService: SessionService,
    public configService: ConfigService
  ) {}

  endChat() {
    this.sessionService.closeSession();
    this.interactionStateService.setToFinished();
  }

  back() {
    this.interactionStateService.setToPreviousState();
  }
}
