import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VisibilityService } from '../visibility/visibility.service';
import { ConfigService, IConfig } from 'app/core/services/config.service';

@Injectable()
export class InitService {
  private res: any;
  private obs: Subject<void>;

  private initilized = false;

  init(): Observable<any> {
    this.obs = new Subject<void>();
    return this.obs;
  }

  constructor(
    private configService: ConfigService,
    private visibilityServise: VisibilityService
  ) {
    console.log('Entered');
    window.LogateChat = window.LogateChat || {};
    window.LogateChat.initialize = this.initialize.bind(this);
  }

  initialize(config: IConfig) {
    console.log('Received config: ', config);

    if (this.initilized) {
      console.error('Chat already initialized.');
      return;
    }

    if (!this.validate(config)) {
      return;
    }

    this.configService.setConfig(config);

    this.initilized = true;
    console.log('Initialized');
    // this.res();
    this.obs.next();
    this.obs.complete();
  }

  isStringHexColor(color: string) {
    console.log('Checking if string ' + color + ' is valid hex string.');

    const hexPattern = new RegExp(/^#([0-9A-F]{3}){1,2}$/i);
    return hexPattern.test(color);
  }

  validate(config: IConfig): boolean {
    //#region validate color values
    if (!!config.color && !this.isStringHexColor(config.color)) {
      console.error('color ' + config.color + ' is not hex string.');
      return false;
    }
    if (
      !!config.submitButtonColor &&
      !this.isStringHexColor(config.submitButtonColor)
    ) {
      console.error(
        'submitButtonColor ' + config.submitButtonColor + ' is not hex string.'
      );
      return false;
    }
    if (
      !!config.submitButtonBackground &&
      !this.isStringHexColor(config.submitButtonBackground)
    ) {
      console.error(
        'submitButtonBackground ' +
          config.submitButtonBackground +
          ' is not hex string.'
      );
      return false;
    }
    if (
      !!config.interactionMessagesBackground &&
      !this.isStringHexColor(config.interactionMessagesBackground)
    ) {
      console.error(
        'interactionMessagesBackground ' +
          config.interactionMessagesBackground +
          ' is not hex string.'
      );
      return false;
    }
    //#endregion
    //#region validate URL values
    if (!!config.termsUrl && !this.isValidUrl(config.termsUrl)) {
      console.error('termsUrl ' + config.termsUrl + ' is not valid URL value.');
      return false;
    }
    if (
      !!config.logoBackgroundImageUrl &&
      !this.isValidUrl(config.logoBackgroundImageUrl)
    ) {
      console.error(
        'logoBackgroundImageUrl ' +
          config.logoBackgroundImageUrl +
          ' is not valid URL value.'
      );
      return false;
    }
    if (
      !!config.operatorLogoBackgroundImageUrl &&
      !this.isValidUrl(config.operatorLogoBackgroundImageUrl)
    ) {
      console.error(
        'operatorLogoBackgroundImageUrl' +
          config.operatorLogoBackgroundImageUrl +
          'is not valid URL value.'
      );
      return false;
    }
    return true;
    //#endregion
  }

  isValidUrl(url: string) {
    let givenURL: URL;
    try {
      givenURL = new URL(url);
    } catch (error) {
      console.log('error in isValidUrl method', error);
      return false;
    }
    return true;
  }
}
