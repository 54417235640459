import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ConfigService } from 'app/core/services/config.service';
import { MessageService } from 'app/core/services/message.service';
import { InteractionEventType } from '../../models/interaction-event-type';
import { Message } from '../../models/message.model';
import { v4 as uuid } from 'uuid';
import { HttpApiService } from 'app/core/services/http-api/http-api.service';

@Component({
  selector: 'cc-chat-interaction-message',
  templateUrl: './interaction-message.component.html',
  styleUrls: ['./interaction-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractionMessageComponent implements OnInit {
  @Input() message: Message;
  @Input() deliveryStatus: InteractionEventType;

  InteractionEventType = InteractionEventType;
  constructor(
    public configService: ConfigService,
    public messageService: MessageService,
    private httpApiService: HttpApiService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  resendMessage() {
    this.messageService.resendMessage(this.message);
  }

  formatMessageContent(message: Message): SafeHtml {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const formattedContent = message.content.replace(urlRegex, (match, url) => {
      const style = message.direction === 'IN' ? 'style="color: white;"' : '';
      return `<a href="${url}" target="_blank" ${style}>${url}</a>`;
    });

    return this.sanitizer.bypassSecurityTrustHtml(formattedContent);
  }

  onQuickReplyClick(message) {
    const messageToSend = new Message({
      externalId: uuid(),
      content: message.content,
      direction: 'IN',
      date: new Date(),
      isQuickReply: false,
      quickReplyData: {
        actionBodyId: message.quickReplyData.actionBodyId,
        actionBodyCode: message.quickReplyData.actionBodyCode,
        imageLink: message.quickReplyData.imageLink,
        previous: message.quickReplyData.previous,
      },
    });

    this.messageService.sendMessage(messageToSend);
  }

  downloadFile() {
    const downloadMethod =
      this.message.predefined == true
        ? this.httpApiService.downloadFileWithoutSessionId(
            this.message.fileData.downloadLink
          )
        : this.httpApiService.downloadFile(this.message.fileData.downloadLink);

    downloadMethod.subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = this.message.fileData.name;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
}
