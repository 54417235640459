<div class="logate-chat-cx">
  <cc-chat-operator-header></cc-chat-operator-header>

  <div class="logate-chat-cx__body">
    <ng-template [ngIf]="!formSubmitted" [ngIfElse]="success">
      <cc-chat-customer-experience-description></cc-chat-customer-experience-description>

      <div class="logate-chat-cx__satisfaction">
        <cc-chat-customer-experience-satisfaction [items]="supportItems">
          {{ "customer-experience.how-satisfied" | translate }}
        </cc-chat-customer-experience-satisfaction>
        <cc-chat-customer-experience-satisfaction
          [multi]="true"
          [items]="betterItems"
        >
          {{ "customer-experience.improve" | translate }}
        </cc-chat-customer-experience-satisfaction>

        <cc-chat-customer-experience-rating></cc-chat-customer-experience-rating>

        <cc-chat-customer-experience-recommend></cc-chat-customer-experience-recommend>
      </div>

      <div class="logate-chat-cx__footer" *ngIf="!formSubmitted">
        <button class="logate-chat-cx__button" (click)="onSubmit()">
          {{ "customer-experience.send" | translate }}
        </button>
      </div>
    </ng-template>

    <ng-template #success>
      <cc-chat-customer-experience-submit></cc-chat-customer-experience-submit>
    </ng-template>
  </div>
</div>
