export enum InteractionEventType {
  TYPING = 'TYPING',
  CHAT_OPENED = 'CHAT_OPENED',
  CHAT_CLOSED = 'CHAT_CLOSED',
  NO_OPERATOR_AVAILABLE = 'NO_OPERATOR_AVAILABLE',
  MESSAGE_DELIVERED = 'MESSAGE_DELIVERED',
  MESSAGE_UNDELIVERABLE = 'MESSAGE_UNDELIVERABLE',
  MESSAGE_ACCEPTED = 'MESSAGE_ACCEPTED',
  MESSAGE_REJECTED = 'MESSAGE_REJECTED',
}
