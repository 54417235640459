import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { SocketConnectionService } from './socket-connection.service';
import {
  Message,
  MessageDTO,
  QuickReplyDTO,
  TextMessageDTO,
} from '../../chat/interaction/models/message.model';
import { ContactService } from './contact.service';
import { LocalStorage } from '../enums/local-storage.enum';
import { InteractionEventType } from 'app/chat/interaction/models/interaction-event-type';
import { v4 as uuid } from 'uuid';

@Injectable()
export class MessageService {
  resetUnreadMessages$ = new BehaviorSubject<boolean>(false);
  messages$ = new BehaviorSubject<Message[]>(
    this.convertLocalStorageToMessage() ?? []
  );

  destroy$ = new Subject<void>();
  GET_STARTED = 'get_started_web_chat_bot_logate';

  TIME_FOR_DELIVERY_STATUS = 2 /**s */ * 1000 /**ms */;

  FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  constructor(
    private socketConnectionService: SocketConnectionService,
    private contactService: ContactService,
    private sessionService: SessionService
  ) {}

  watchForIncomingMessages(resetUnreadMessages = true): Observable<any> {
    this.destroy$ = new Subject<void>();
    if (resetUnreadMessages) {
      this.resetUnreadMessages$.next(false);
    }
    return this.socketConnectionService
      .watchMessages()
      .pipe(takeUntil(this.destroy$))
      .pipe(
        map((message) => JSON.parse(message.body)),
        map((message) => this.mapMessageDTOToMessages(message)),
        tap((message) => this.updateMultipleMessages(message))
      );
  }

  sendGetStartedMessage() {
    const message = new Message({
      externalId: uuid(),
      content: this.GET_STARTED,
      direction: 'IN',
      date: new Date(),
      quickReplyData: {
        actionBodyId: 1,
        actionBodyCode: this.GET_STARTED,
      },
    });
    this.sendMessage(message);
  }

  convertLocalStorageToMessage() {
    this.resetUnreadMessages$.next(true);
    if (localStorage.getItem(LocalStorage.LOGATE_CHAT_MESSAGES)) {
      return JSON.parse(
        localStorage.getItem(LocalStorage.LOGATE_CHAT_MESSAGES)
      ).map((message) => {
        const convertedMessage = new Message({
          deliveryStatus: message.deliveryStatus,
          externalId: message?.externalId,
          content: message.content,
          direction: message.direction,
          date: new Date(message.date),
          isQuickReply: message.isQuickReply,
          disabled: message.disabled,
          predefined: message.predefined,
          bgColor: message.bgColor,
          imageLink: message.imageLink,
          textColor: message.textColor,
          quickReplyData: message.quickReplyData
            ? {
                externalId: message.quickReplyData.externalId,
                actionBodyCode: message.quickReplyData.actionBodyCode,
                actionBodyId: message.quickReplyData.actionBodyId,
                bgColor: message.quickReplyData.bgColor,
                imageLink: message.quickReplyData.imageLink,
                message: message.quickReplyData.text,
                textColor: message.quickReplyData.textColor,
                previous: message.quickReplyData.previous,
              }
            : undefined,
          file: message.file,
          fileData: message.fileData,
        });
        return convertedMessage;
      });
    }
  }

  resendMessage(message: Message) {
    // first step is to delete message from messages$ and localStorage
    this.deleteMessage(message);
    // send again as regular message
    this.sendMessage(message);
  }

  formatFileName(fileName: string): string {
    // Extracting the base name and extension
    const lastDotIndex = fileName.lastIndexOf('.');
    const baseName = fileName.substring(0, lastDotIndex);
    const extension = fileName.substring(lastDotIndex + 1);

    // Taking the first 5 characters and creating the formatted file name
    const truncatedName = baseName.substring(0, 5);
    const formattedFileName = `${truncatedName}(...).${extension}`;

    return formattedFileName;
  }

  formatFileSize(sizeInBytes: number): string {
    const units = this.FILE_SIZE_UNITS;
    let power = Math.round(Math.log(sizeInBytes) / Math.log(1024));
    power = Math.min(power, units.length - 1);
    const size = sizeInBytes / Math.pow(1024, power); // size in new units
    const formattedSize = Math.round(size * 100) / 100; // keep up to 2 decimals
    const unit = units[power];
    return `${formattedSize} ${unit}`;
  }

  deleteMessage(message: Message) {
    const messageIndex = this.messages$
      .getValue()
      .findIndex((m) => m.externalId === message.externalId);
    if (messageIndex === -1) {
      return;
    }

    const messages = this.messages$.getValue();
    messages.splice(messageIndex, 1);
    this.messages$.next(messages);
    localStorage.setItem(
      LocalStorage.LOGATE_CHAT_MESSAGES,
      JSON.stringify(this.messages$.getValue())
    );
  }

  sendMessage(message: Message): void {
    const request = {
      externalId: message.externalId,
      message: message.content,
      chatSessionId: this.sessionService.getChatSessionId(),
      time: message.date,
      ...(message.quickReplyData
        ? { quickReplyData: message.quickReplyData }
        : {}),
    };

    const headers = {
      externalId: request.externalId,
      chatSessionId: this.sessionService.getChatSessionId(),
    };
    if (this.sessionService.getChatSessionId()) {
      this.socketConnectionService.publishMessage(request, headers);
      this.updateMessages(message);
      this.setPreviousMessagesToDisabled();
    }
  }

  checkDeliveryStatus = (message: Message) => {
    // if there is no still delivery status to message, set it to MESSAGE_UNDELIVERABLE
    const messageInStore = this.messages$
      .getValue()
      .find((m) => m.externalId === message.externalId);
    // if there is no message in store, return
    if (!messageInStore) {
      return;
    }
    // if there is already delivery status, return
    if (messageInStore.deliveryStatus) {
      return;
    }

    this.setDeliveryStatus(
      message.externalId,
      InteractionEventType.MESSAGE_UNDELIVERABLE
    );
  };

  updateMultipleMessages(messages: Message[]) {
    messages.forEach((m) => {
      this.updateMessages(m);
    });
  }

  setPreviousMessagesToDisabled() {
    const currentMessages = this.messages$.getValue();

    const updatedMessages: Message[] = currentMessages.map((message) => {
      // Create a new Message object with the updated properties
      return new Message({
        ...message,
        disabled: true,
      });
    });

    this.messages$.next(updatedMessages);
  }

  createErrorMessage(error: string) {
    const message = new Message({
      externalId: uuid(),
      direction: 'OUT',
      content: `Error occurred during sending files: ${error}`,
      date: new Date(),
      isQuickReply: false,
    });
    this.updateMessages(message);
  }

  updateMessages(message: Message): void {
    if (message.content === this.GET_STARTED) {
      return;
    }
    // check if there is already message with same externalID
    if (
      !this.existingExternalId(this.messages$.getValue(), message.externalId)
    ) {
      this.messages$.next([...this.messages$.getValue(), message]);
      localStorage.setItem(
        LocalStorage.LOGATE_CHAT_MESSAGES,
        JSON.stringify(this.messages$.getValue())
      );
    }
    if (message.direction === 'IN') {
      setTimeout(
        this.checkDeliveryStatus,
        this.TIME_FOR_DELIVERY_STATUS,
        message
      );
    }
  }

  existingExternalId(messages: Message[], externalId: string) {
    return messages.some((m) => m.externalId === externalId);
  }

  getNumberOfMessages(): Observable<number> {
    return this.messages$.pipe(map((messages) => messages.length));
  }

  private createMessage(
    content: string,
    externalId: string,
    direction: 'IN' | 'OUT' = 'OUT'
  ): Message {
    return new Message({
      externalId,
      content,
      direction,
      date: new Date(),
      isQuickReply: false,
    });
  }

  mapMessageDTOToMessages(messageDTO: MessageDTO): Message[] {
    const textMessages: Message[] = messageDTO.messages.map(
      (textMessageDTO: TextMessageDTO) => {
        return new Message({
          externalId: textMessageDTO.externalId,
          direction: 'OUT',
          content: textMessageDTO.message,
          date: textMessageDTO.time,
          isQuickReply: false,
          file: textMessageDTO.file,
          predefined: textMessageDTO.predefined,
          textColor: textMessageDTO.textColor,
          bgColor: textMessageDTO.bgColor,
          imageLink: textMessageDTO.imageLink,
          fileData: {
            name: textMessageDTO.fileName,
            downloadLink: textMessageDTO.downloadLink,
          },
        });
      }
    );
    const quickReplyMessages: Message[] = messageDTO.quickReplies.map(
      (quickReplyDTO: QuickReplyDTO) => {
        return new Message({
          externalId: quickReplyDTO.externalId,
          direction: 'OUT',
          content: quickReplyDTO.message,
          date: new Date(),
          isQuickReply: true,
          disabled: false,
          predefined: true,
          quickReplyData: quickReplyDTO,
          textColor: quickReplyDTO.textColor,
          bgColor: quickReplyDTO.bgColor,
          imageLink: quickReplyDTO.imageLink,
        });
      }
    );

    return textMessages.concat(quickReplyMessages);
  }

  public setDeliveryStatus(
    externalId: string,
    deliveryStatus: InteractionEventType
  ) {
    // find external id in messages array
    const messages = this.messages$.getValue();
    const messageIndex = this.messages$
      .getValue()
      .findIndex((message) => message.externalId === externalId);
    if (messageIndex === -1) {
      return;
    }
    messages[messageIndex].deliveryStatus = deliveryStatus;
    this.messages$.next(messages);
    localStorage.setItem(
      LocalStorage.LOGATE_CHAT_MESSAGES,
      JSON.stringify(messages)
    );
  }

  resetMessages(): void {
    this.messages$.next([]);
    localStorage.setItem(LocalStorage.LOGATE_CHAT_MESSAGES, JSON.stringify([]));
  }

  /** In first message we are sending contact info to operator. We need to remove it before show it to client */
  removeContactInfoFromMessage(message: Message): Message {
    if (this.messages$.getValue().length === 0) {
      message.content = message.content.replace(
        this.contactService.parseContactInfo(),
        ''
      );
    }

    return message;
  }

  destroyService() {
    this.messages$.next([]);
    this.resetUnreadMessages$.next(false);
    localStorage.setItem(LocalStorage.LOGATE_CHAT_MESSAGES, JSON.stringify([]));
    this.destroy$.next();
    this.destroy$.complete();
  }
}
