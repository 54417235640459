import { NgModule } from '@angular/core';
import { MinimizedComponent } from './minimized.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    MinimizedComponent
  ],
  exports: [
    MinimizedComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MinimizedModule {
}
