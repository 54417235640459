import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cc-chat-customer-experience-rating',
  templateUrl: './customer-experience-rating.component.html',
  styleUrls: ['./customer-experience-rating.component.scss']
})
export class CustomerExperienceRatingComponent implements OnInit {
  selectedItem: number;
  @Output() selected = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(item: number) {
    this.selectedItem = item;
    this.selected.emit(item);
  }

}
