import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TypingEventService } from './services/typing-event.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InteractionStateService } from './services/interaction-state.service';
import { InteractionState } from './models/interaction-state';
import { MessageService } from 'app/core/services/message.service';
import { ConfigService } from 'app/core/services/config.service';

@Component({
  selector: 'cc-chat-interaction',
  templateUrl: './interaction.component.html',
  styleUrls: ['./interaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractionComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  InteractionState = InteractionState;
  state: InteractionState;

  constructor(
    public typingEventService: TypingEventService,
    public interactionStateService: InteractionStateService,
    private cd: ChangeDetectorRef,
    public configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.interactionStateService.state$.subscribe((state) => {
      this.state = state;
      this.cd.detectChanges();
    });
    this.typingEventService
      .getTyping()
      .subscribe(() => this.cd.detectChanges());
    this.typingEventService
      .initWatcher()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
