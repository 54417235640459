export class DateParser {
  static months: string[] = [
    'Januar',
    'Februar',
    'Mart',
    'April',
    'Maj',
    'Jun',
    'Jul',
    'Avgust',
    'Septembar',
    'Oktobar',
    'Novembar',
    'Decembar'
  ];

  static getMonth(month: number): string {
    return this.months[month];
  }
}
