import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cc-chat-customer-experience-description',
  templateUrl: './customer-experience-description.component.html',
  styleUrls: ['./customer-experience-description.component.scss']
})
export class CustomerExperienceDescriptionComponent implements OnInit {

  ngOnInit(): void {
  }

}
