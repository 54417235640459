import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  ConfigService,
  IActiveChannels,
} from '../../core/services/config.service';
import { StateService } from '../../core/services/state.service';
import { State } from '../../core/enums/state.enum';
import { ChannelTypes } from './ChannelTypes';
import { DomSanitizer } from '@angular/platform-browser';
import { InteractionStateService } from '../interaction/services/interaction-state.service';
import { InteractionState } from '../interaction/models/interaction-state';

@Component({
  selector: 'cc-chat-chose-interaction-type',
  templateUrl: './chose-interaction-type.component.html',
  styleUrls: ['./chose-interaction-type.component.scss'],
})
export class ChoseInteractionTypeComponent implements OnInit {
  ChannelTypes = ChannelTypes;
  State = State;
  color: string;
  activeChannels: IActiveChannels;
  hoveredChannel: string | null = null;
  safeUrl: any;
  constructor(
    public configService: ConfigService,
    private stateService: StateService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private interactionStateService: InteractionStateService
  ) {}

  ngOnInit(): void {
    this.color = this.configService.config.getValue().color;
    this.activeChannels = this.configService.config.getValue().activeChannels;
    this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(
      'viber://pa?chatURI=' + this.activeChannels.viber
    );
  }

  close(): void {
    if (
      (this.configService.getHidden() !== undefined &&
        this.configService.getShowonclose() === undefined) ||
      this.configService.getShowonclose() === false
    ) {
      this.configService.setHidden(true);
    } else {
      this.stateService.setToMinimized();
    }
  }

  choseWebChat(): void {
    if (this.interactionStateService.getState() === InteractionState.FINISHED) {
      this.stateService.changeState(State.INTERACTION);
    } else this.stateService.changeState(State.DETAIL);
  }

  onMouseOut(): void {
    this.hoveredChannel = null;
    this.cdr.detectChanges();
  }

  onMouseOver(channel: ChannelTypes): void {
    this.hoveredChannel = channel;
    this.cdr.detectChanges();
  }
}
