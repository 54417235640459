<svg
  xmlns="http://www.w3.org/2000/svg"
  width="28"
  height="26"
  viewBox="0 0 28 26"
>
  <g fill="none" fill-rule="evenodd" transform="translate(2 2.09)">
    <path
      [attr.stroke]="hoveredChannel == ChannelTypes.WEB_CHAT ? color : 'white'"
      stroke-width="2"
      d="M12,-1 C15.5551002,-1 18.7767371,0.208945729 21.1134701,2.14013008 C23.5280026,4.13561144 25,6.89599794 25,9.91735537 C25,12.9387128 23.5280026,15.6990993 21.1134701,17.6945807 C18.7767371,19.625765 15.5551002,20.8347107 12,20.8347107 C11.3569769,20.8347107 10.7139491,20.7939236 10.0706818,20.7124866 C8.19166395,21.9314212 6.008786,22.8181818 4,22.8181818 C3.54309844,22.8181818 3.04422953,22.5745236 2.72563937,22.1005878 C2.55749673,21.8504583 2.46770699,21.5780444 2.44585955,21.3142645 C2.55574739,21.1828219 2.66826,21.0468286 2.78418228,20.9063168 C3.44849517,20.101089 3.98839862,19.4339959 4.36987835,18.7611642 C2.86090236,17.8333986 1.5980762,16.6396322 0.678783295,15.2720891 C-0.392638595,13.6782383 -1,11.8485941 -1,9.91735537 C-1,6.89599794 0.471997417,4.13561144 2.88652986,2.14013008 C5.22326292,0.208945729 8.44489975,-1 12,-1 L12,-1 Z"
    />
    <rect
      width="12.545"
      height="1.636"
      x="6"
      y="4.909"
      [attr.fill]="hoveredChannel == ChannelTypes.WEB_CHAT ? color : 'white'"
      rx=".818"
    />
    <rect
      width="12.545"
      height="1.636"
      x="6"
      y="9.273"
      [attr.fill]="hoveredChannel == ChannelTypes.WEB_CHAT ? color : 'white'"
      rx=".818"
    />
    <rect
      width="12.545"
      height="1.636"
      x="6"
      y="13.091"
      [attr.fill]="hoveredChannel == ChannelTypes.WEB_CHAT ? color : 'white'"
      rx=".818"
    />
  </g>
</svg>
