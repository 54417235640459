<svg
  xmlns="http://www.w3.org/2000/svg"
  width="30"
  height="22"
  viewBox="0 0 16 12"
>
  <g
    fill="none"
    fill-rule="evenodd"
    [attr.stroke]="hoveredChannel == ChannelTypes.EMAIL ? color : 'white'"
    transform="translate(0 .668)"
  >
    <rect width="14.333" height="9.667" x=".5" y=".5" rx="2" />
    <polyline
      stroke-linecap="round"
      stroke-linejoin="round"
      points="14.536 1.388 7.667 7.333 .797 1.388"
    />
  </g>
</svg>
