import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { RxStompConfig } from '@stomp/rx-stomp';
import { Versions } from '@stomp/stompjs';
import { environment } from '../../../environments/environment';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class StompConfigService {
  constructor(
    @Inject(APP_BASE_HREF) private href: string,
    private configService: ConfigService
  ) {}

  /**
   * Return configuration for RxStomp.
   *
   * @param path Path websocket-a (npr. 'api/ws').
   *
   */
  config(path = 'api/ws'): RxStompConfig {
    const clientId = this.configService.getConfig().clientId;
    path = path + '?clientId=' + clientId;
    let sourceLocation = window.location;
    let pathname = '/chat';
    const logateChatElement = document.getElementById('logate-chat');
    if (logateChatElement) {
      sourceLocation = new URL((logateChatElement as any)?.src) as any;
      pathname = sourceLocation?.pathname.substring(
        0,
        sourceLocation?.pathname.lastIndexOf('/')
      );
    }

    const protocol =
      (sourceLocation as any)?.protocol === 'https:' ? 'wss' : 'ws';
    // const protocol = 'ws';
    const host = (sourceLocation as any).host + pathname;
    console.log('Socket url: ', `${protocol}://${host}/${path}`);

    return {
      brokerURL: `${protocol}://${host}/${path}`,
      // brokerURL: 'wss://192.168.27.235/chat/api/ws?clientId=test1',
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      reconnectDelay: 500,
      debug: environment.production
        ? (str) => {
            // console.log(str);
          }
        : (str) => {
            console.log(str);
          },
      stompVersions: new Versions(['1.2']),
    };
  }
}
