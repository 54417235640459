import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contact: string;
  phone: string;

  setContactInfo(contact: string): void {
    this.contact = contact;
  }

  parseContactInfo(): string {
    let contactInfo = this.addContact();

    if (contactInfo) {
      contactInfo += ', Poruka: ';
    }

    return contactInfo;
  }

  addContact(): string {
    if (this.contact) {
      return `Kontakt: ${this.contact}`;
    }

    return '';
  }

  destroyService() {
    this.contact = undefined;
    this.phone = undefined;
  }
}
