<form class="logate-chat-detail-form" [formGroup]="form">
  <div class="logate-chat-detail-form__group">
    <div class="logate-chat-detail-form__title">
      {{
        (this.configService.getConfig().alternateTexts?.formEnterName
          ? "alternate-texts." +
            this.configService.getConfig().alternateTexts?.formEnterName
          : "detail.form.enter-name-and-lastname"
        ) | translate
      }}
    </div>
    <input
      class="logate-chat-detail-form__input"
      [ngClass]="{
        'logate-chat-detail-form__input--valid':
          (nickname.touched || nickname.dirty) && nickname.valid,
        'logate-chat-detail-form__input--error':
          (nickname.touched || nickname.dirty) && nickname.invalid
      }"
      placeholder="{{
        (this.configService.getConfig().alternateTexts?.formEnterNamePlaceholder
          ? 'alternate-texts.' +
            this.configService.getConfig().alternateTexts
              ?.formEnterNamePlaceholder
          : 'detail.form.enter-name-and-lastname-placeholder'
        ) | translate
      }}
    "
      formControlName="nickname"
    />
  </div>

  <div class="logate-chat-detail-form__group">
    <div class="logate-chat-detail-form__title">
      {{
        (this.configService.getConfig().alternateTexts?.formEnterNumber
          ? "alternate-texts." +
            this.configService.getConfig().alternateTexts?.formEnterNumber
          : "detail.form.enter-email-number"
        ) | translate
      }}
    </div>
    <input
      class="logate-chat-detail-form__input"
      placeholder="{{
        (this.configService.getConfig().alternateTexts
          ?.formEnterNumberPlaceholder
          ? 'alternate-texts.' +
            this.configService.getConfig().alternateTexts
              ?.formEnterNumberPlaceholder
          : 'detail.form.enter-email-number-placeholder'
        ) | translate
      }}"
      formControlName="contact"
    />
    <div
      class="logate-chat-detail-form__input--txt-error"
      *ngIf="
        form.controls.contact?.errors?.pattern && form.controls.contact?.touched
      "
    >
      {{ "detail.form.enter-email-number-error" | translate }}
    </div>
  </div>
</form>
