<div class="logate-chat-cx-submit">
  <h1 class="logate-chat-cx-submit__title">NLB</h1>
  <h2 class="logate-chat-cx-submit__subtitle">
    {{ "customer-experience.submit.subtitle" | translate }}
  </h2>

  <div
    class="logate-chat-cx-submit__content logate-chat-customer-experience__box"
  >
    <div class="logate-chat-customer-experience__title">
      {{ "customer-experience.submit.thank-you" | translate }}
    </div>
    <div class="logate-chat-cx-submit__icon"></div>
  </div>
</div>
