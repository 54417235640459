import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChoseInteractionTypeComponent } from './chose-interaction-type.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WebChatIconComponent } from './web-chat-icon/web-chat-icon.component';
import { PhoneIconComponent } from './phone-icon/phone-icon.component';
import { EmailIconComponent } from './email-icon/email-icon.component';
import { ViberIconComponent } from './viber-icon/viber-icon.component';

@NgModule({
  declarations: [ChoseInteractionTypeComponent, WebChatIconComponent, PhoneIconComponent, EmailIconComponent, ViberIconComponent],
  exports: [ChoseInteractionTypeComponent],
  imports: [CommonModule, FormsModule, TranslateModule],
})
export class ChoseInteractionTypeModule {}
