<form class="logate-chat-detail-term" [formGroup]="form">
  <label class="logate-chat-detail-term__group">
    <span class="logate-chat-detail-term__group--container">
      <input type="checkbox" formControlName="termsOfUse" />
      <span class="logate-chat-detail-term__checkmark"></span>
    </span>
    <span
      class="logate-chat-detail-term__text logate-chat-detail-term__text--with-link"
    >
      {{ "detail.terms.agree" | translate }}
      <a
        href="{{ (configService.config$ | async)?.termsUrl }}"
        target="_blank"
        class="logate-chat-detail-term__link"
        >{{ "detail.terms.agree2" | translate }}</a
      >
    </span>
  </label>
  <label class="logate-chat-detail-term__group">
    <ng-container *ngIf="!this.configService.getConfig().alternateTerms">
      <span class="logate-chat-detail-term__group--container">
        <input type="checkbox" formControlName="acceptTerms" />
        <span class="logate-chat-detail-term__checkmark"></span>
      </span>
      <span class="logate-chat-detail-term__text">
        {{ "detail.terms.consent" | translate }}
      </span></ng-container
    >

    <ng-container *ngIf="this.configService.getConfig().alternateTerms">
      <div class="logate-chat-detail-term__disclaimer">
        <span class="logate-chat-detail-term__text">
          {{ "detail.terms.saving-chat" | translate }}
        </span>
        <span class="logate-chat-detail-term__text">
          {{ "detail.terms.thanks-understanding" | translate }}
        </span>
      </div>
    </ng-container>
  </label>
</form>
