import { DateParser } from '../../../core/parsers/date.parser';
import { InteractionEventType } from './interaction-event-type';
import * as moment from 'moment';

export class Message {
  externalId?: string;
  direction: 'IN' | 'OUT';
  content: string;
  date: Date;
  deliveryStatus?: InteractionEventType;
  isQuickReply?: boolean;
  quickReplyData?: QuickReplyDTO;
  file?: boolean;
  fileData?: FileData;
  disabled?: boolean;
  predefined?: boolean;
  textColor?: string;
  bgColor?: string;
  imageLink?: string;

  constructor(init?: Partial<Message>) {
    Object.assign(this, init);
  }

  getFormattedDate() {
    this.date = new Date(this.date);

    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      this.date
    );
    const month = DateParser.getMonth(this.date.getMonth());
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      this.date
    );
    const hour = new Intl.DateTimeFormat('en', {
      hour: '2-digit',
      hour12: false,
    }).format(this.date);
    const minutes = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(
      this.date
    );
    return `${month} ${day}. ${year}. u ${hour}:${
      minutes.length === 2 ? minutes : '0' + minutes
    }`;
  }

  getFormattedTime() {
    return moment.utc(this.date).local().format('HH: mm');
  }
}

export class FileData {
  name: string;
  size?: number;
  downloadLink?: string;
}

export class MessageDTO {
  chatSessionId: string;
  messages: TextMessageDTO[];
  quickReplies: QuickReplyDTO[];
}

export class TextMessageDTO {
  message: string;
  file: boolean;
  fileName?: string;
  downloadLink?: string;
  time: Date;
  externalId: string;
  predefined?: boolean;
  textColor?: string;
  bgColor?: string;
  imageLink?: string;
}

export class QuickReplyDTO {
  actionBodyId: number;
  actionBodyCode: string;
  message?: string;
  textColor?: string;
  bgColor?: string;
  imageLink?: string;
  externalId?: string;
  previous?: boolean;
  predefined?: boolean;
}
