import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Message } from '../models/message.model';
import { MessageService } from '../../../core/services/message.service';
import { TypingEventService } from '../services/typing-event.service';
import { openCloseAnimation } from '../../../core/animations/openCloseAnimation';
import { Subject } from 'rxjs';
import { ContactService } from '../../../core/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { HttpApiService } from 'app/core/services/http-api/http-api.service';
import { InteractionEventType } from '../models/interaction-event-type';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'cc-chat-interaction-form',
  templateUrl: './interaction-form.component.html',
  styleUrls: ['./interaction-form.component.scss'],
  animations: [...openCloseAnimation],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class InteractionFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  isEmojiPickerVisible = false;
  messageNumber: number;
  file: File;

  destroy$ = new Subject<void>();

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private messageService: MessageService,
    private contactService: ContactService,
    private typingEventService: TypingEventService,
    private cd: ChangeDetectorRef,
    private httpApiService: HttpApiService
  ) {}

  ngOnInit(): void {
    this.prepareForm();
    this.watchForMessageChanges();
    this.watchForMessageNumber();
  }

  handleFileInputClick(event: MouseEvent) {
    event.stopPropagation();
  }

  showFileInput(): void {
    this.isEmojiPickerVisible = false;
    this.fileInput.nativeElement.click();
    this.cd.detectChanges();
  }

  handleFileInputChange(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      console.log('Selected file:', files[0]);
    }
    this.file = files[0];
    this.fileInput.nativeElement.value = ''; // Clear the selected file (optional)
    this.fileInput.nativeElement.style.display = 'none';
    this.form.markAsTouched();
    this.cd.detectChanges();
  }

  removeItem() {
    console.log(1111);
    this.file = null;
    this.cd.detectChanges();
  }

  stopEventPropagation(event: Event) {
    event.stopPropagation();
  }

  prepareForm(): void {
    this.form = new UntypedFormGroup({
      message: new UntypedFormControl('', Validators.required),
    });
  }

  uploadfile(file: File, message: Message) {
    this.httpApiService.uploadFile(file, message.externalId).subscribe(
      (_) => {
        message.fileData = {
          name: this.file.name,
          size: this.file.size,
        };
        message.file = true;
        this.file = null;
        message.deliveryStatus = InteractionEventType.MESSAGE_ACCEPTED;
        this.messageService.updateMessages(message);
        this.messageService.setPreviousMessagesToDisabled();
        this.cd.detectChanges();
      },
      (error) => {
        this.prepareErrorMessage(error);
        this.cd.detectChanges();
      }
    );
  }

  prepareErrorMessage(error: HttpErrorResponse) {
    let errorMessage = 'An unexpected error occurred';
    if (error.error && error.error.error) {
      errorMessage = error.error.error;
    }
    this.messageService.createErrorMessage(errorMessage);
  }

  sendMessage(): void {
    const content = this.message.value;
    const message = new Message({
      externalId: uuid(),
      content,
      direction: 'IN',
      date: new Date(),
      isQuickReply: false,
    });

    if (this.file) {
      this.uploadfile(this.file, message);
      return;
    }

    this.messageService.sendMessage(message);
    this.form.reset();
    this.isEmojiPickerVisible = false;
  }

  addEmoji(event): void {
    const value = (this.message.value ?? '') + event.emoji.native;

    this.message.setValue(value);
    this.isEmojiPickerVisible = false;
    this.cd.detectChanges();
  }

  toggleIsEmojiPickerVisible(): void {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    this.cd.detectChanges();
  }

  // When user start typing. We are sending event for typing
  private watchForMessageChanges(): void {
    this.message.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.detectChanges();
      this.publishEventTyping();
    });
  }

  private publishEventTyping(): void {
    this.typingEventService.publishTyping();
  }

  private watchForMessageNumber(): void {
    this.messageService
      .getNumberOfMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.messageNumber = value));
  }

  private getContactInfoForFirstMessage(): string {
    if (this.messageNumber || this.messageNumber > 1) {
      return '';
    }

    return this.contactService.parseContactInfo();
  }

  get message(): AbstractControl {
    return this.form.get('message');
  }

  enterClicked(event) {
    const messageContent = this.message?.value?.trim();
    if (!messageContent) {
      event.preventDefault();
      return;
    }
    // Enter was pressed without shift key
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault(); // Stops enter from creating a new line
      this.sendMessage();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
