<div
  class="logate-chat-wrapper"
  [style.background-color]="
    (configService.config$ | async)?.interactionMessagesBackground
  "
>
  <div class="logate-chat-finish-dialog">
    <div class="logate-chat-smiley"></div>
    <div class="logate-chat-title">
      {{ title | translate }}
    </div>
    <div class="logate-chat-subtitle">
      {{ subtitle | translate }}
    </div>
    <button
      class="logate-chat-button logate-chat-button__grey"
      (click)="backToOldChat()"
    >
      {{ "interaction.ended.back-to-old" | translate }}
    </button>
    <button
      class="logate-chat-button logate-chat-button__green"
      (click)="startChatAgain()"
    >
      {{ "interaction.ended.begin-new" | translate }}
    </button>
    <button
      class="logate-chat-button"
      [style.background-color]="(configService.config$ | async)?.color"
      (click)="openSurveyLink()"
      *ngIf="surveyLink.length > 0"
    >
      {{ "interaction.ended.survey-link" | translate }}
    </button>
  </div>
</div>
